<template>
  <div class="rightTool" :style="{ right: 0.05 + 'rem', bottom: 0.1 + 'rem' }">
    <div class="legend" :style="{ bottom: 0.1 + 'rem' }">
      <div>
        <li v-for="(item, index) in legendImg" :key="'legend' + index">
         
          <img
            v-if="item.pic"
            :src="item.check ? item.pic : item.pic2"
            class="icon"
            style="width:18px;margin-left:18px;margin-right:13px;"
          />
          <span
            v-else
            :class="['icon', 'iconfont', item.img]"
            :style="{
              fontSize: item.size || '16px',
              color: item.check ? item.color || '#0b71f3' : '#a5b4c4'
            }"
          ></span>

          <span :class="item.check ? 'active' : 'text'">{{ item.name }}</span>
        </li>

       
      </div>
    </div>
 
   
  </div>
</template>

<script>
// import { saveMake } from '../../apis/commonType'
// import { queryFeatrue } from '../../apis/query'

import { constantDict , serverMap, commonLayer} from '../Gis/utils/dict'

// import { constantDict, serverMap, commonLayer } from '../../utils/dict.js'
export default {
  name: 'Bar',
  components: {},
  data() {
    return {
      legendData: {},
      zoom: 14,
      Satellite: null,
      bottomPosition:1,
      dialogVisible:false,
      analysis: 0,
      MapArray: [
        { img: require('../../assets/gis/tool/ysh.png'), value: 'grey' },
        { img: require('../../assets/gis/tool/bz.png'), value: 'normal' },
        { img: require('../../assets/gis/tool/wx.png'), value: 'wx' },
        { img: require('../../assets/gis/tool/dql.png'), value: 'blue' },
        { img: require('../../assets/gis/tool/jyl.png'), value: 'darkblue' }
      ],
      legendImg: [
        {
          name: '供气站',
          img: 'icon-menzhan',
          size: '12px',
          color: '#c83365',
          id: constantDict.gongqizhan.dictCode,
          check: true
        },
        {
          name: '调压设施',
          img: 'icon-tiaoyazhan',
          type: 'pic',
          pic: require('../../assets/gis/map/right1.png'),
          pic2: require('../../assets/gis/map/right2.png'),
          id: constantDict.yonghuzhan.dictCode,
          check: true
        },
        {
          name: '阀门',
          img: 'icon-famen1',
          color: '#ff0000',
          size: '10px',
          id: serverMap['1002'][0].dictCode,
          check: true
        },
       
        // {
        //   name: '监测点',
        //   img: 'icon-a-jiedian1-copy',
        //   color: '#d81e06',
        //   id: constantDict.jiancedian.dictCode,
        //   check: true
        // },
        // {
        //   name: '重点设施',
        //   img: 'icon-ranqisheshi',
        //   color: '#00a29a',
        //   id: constantDict.zhongdian.dictCode,
        //   check: true
        // },
        //  {
        //   name: '隐蔽工程',
        //   img: 'icon-a-23gongcheng',
        //   color: '#d81e06',
        //   id: constantDict.yinbigongc.dictCode,
        //   check: true
        // },
          {
          name: '三方施工',
          img: 'icon-shigong2',
          color: '#ff6000',
          id: constantDict.sanfang.dictCode,
          check: true
        },
          {
          name: '检测点',
          img: 'icon-a-jiedian1-copy',
          color: '#a807bb',
          id: constantDict.jiancedianNew.dictCode,
          check: true
        },
        //  {
        //   name: '三通',
        //   img: 'icon-santong',
        //   size: '10px',
        //   id: serverMap['1003'][0].dictCode,
        //   color: '#6cde77',
        //   check: true
        // },
        { name: '变径', img: 'icon-bianjing', size: '10px', id: serverMap['1003'][1].dictCode ,check: true},
        // {
        //   name: '钢塑转换',
        //   img: 'icon-gangsuzhuanhuan',
        //   size: '0.14rem',
        //   id: serverMap['1003'][2].dictCode,
        //   check: true,
        //   color:'#d072fc'
        // },
        {
          name: '节点',
          img: 'icon-a-jiedian1',
          id: constantDict.jiedian.dictCode,
          check: false,
          color:'#0b71f3'
        },
        {
          name: '重合节点',
          img: 'icon-zhonghejiedian',
          id: constantDict.chonghejiedian.dictCode,
          check: true,
          color:'#ff7d2e'
        },
        {
          name: '其他管件',
          img: 'icon-qitaguanjian',
          id: constantDict.qitaguanjian.dictCode,
          check: true,
          color:'#0b71f3'
        },
        {
          name: '管线点',
          img: 'icon-a-guanxiandian2',
          id: constantDict.guanxiandian.dictCode,
          check: true,
          color:'#0b71f3'
        }
      ],
      
      toolActive: 'drag',
      FullScreenBool: true,
      postionVal: 0,
      legendPosition: 1,
      mapchangePosition: 0.1,
      dialogTableVisible: false,wms:null
    }
  },
  computed: {},
  watch: {
    
  },
  mounted() {

  },
  methods: {
    getwms(){
      if (this.wms) {
        this.$parent.map.remove(this.wms)
      }
       this.wms = new AMap.TileLayer.WMS({
        url: '/geoserver/wms',
        blend: false,
        tileSize: 512,
        dataZooms: [0, 25],
        zooms: [0, 25],
        zIndex: 10,
        detectRetina: false,
        //  visible:false,
        params: {
          LAYERS:commonLayer.pressure ,
          VERSION: '1.3.0',
          CQL_FILTER:'node_type2 <> 0'
        }
      })
      this.wms.setMap(this.$parent.map)
    },
    selectSketch(item) {
      if(this.$parent.gis){
          this.$parent.$refs.topTool.clearTopTool()
      }
      
      this.$set(item, 'check', !item.check)
      this.setSketch()
    },
    setSketch() {
      let sketch = this.sketchImg
      let type = []
      sketch.forEach(item => {
        if (item.check) {
          type.push(...item.type)
        }
      })
      this.$store.commit('gis/sketchTypeChange', type)
      this.$eventBus.$emit('setMarker')
    },
    selectLayer(item) {
      this.analysis = 0
      this.$set(item, 'check', !item.check)
      this.setLayer()
    },
    setLayer() {
      let legendImg = this.legendImg
      let layer = []
      legendImg.forEach(item => {
        if (item.check) {
          layer.push(item.id)
        }
      })
      console.log(layer)
      this.$store.commit('gis/legendSelectChange', layer)
      this.$eventBus.$emit('setFilter')
    },
    openQuery() {
      this.$parent.openQuery()
    },
    saveAdd(obj) {
      saveMake(obj).then(res => {
        if (res.code == 200) {
          this.$message.success('保存成功')
          this.$parent.clearDraw()
        } else {
        }
      })
    },
    closeTool() {
      this.$emit('closeDraw')
      this.$parent.drawState = false
    },
    draw(value, type) {
      this.closeTool()
      if (type == 'draw') {
        if (this.toolActive == value) {
          this.toolActive = ''
        } else {
          this.$emit('draw', value)
          this.toolActive = value
        }
      } else if (type == 'drag') {
        if (this.toolActive == value) {
          this.toolActive = ''
        } else {
          this.toolActive = value
        }
      }
    },
    displaySettings() {
      this.$emit('settingsItem', 'dialogDisplay')
    },
    examineLineSettings() {
      if (this.analysis == 1) {
        this.analysis = 0
        this.$eventBus.$emit('setFilter')
      } else {
        this.analysis = 1
        this.$eventBus.$emit('analysisLayer', { name: commonLayer.groupLine })
      }
    },
    examinePointSettings() {
      if (this.analysis == 2) {
        this.analysis = 0
        this.$eventBus.$emit('setFilter')
      } else {
        this.analysis = 2
        this.$eventBus.$emit('analysisLayer', { name: commonLayer.groupPoint })
      }
    },
    MapChangeFun(value) {
      console.log(22222222)
      if (value == 'wx') {
        this.Satellite = new AMap.TileLayer.Satellite()
        this.$parent.map.add(this.Satellite)
      } else {
        if (this.Satellite) {
          this.$parent.map.remove(this.Satellite)
        }
        let styleName = 'amap://styles/' + value
        this.$parent.map.setMapStyle(styleName)
      }

      if (value == "normal") {
        document.body.classList.remove("black");
        this.$store.commit("colorChange", "");
      } else {
        document.body.classList.add("black");
        this.$store.commit("colorChange", "black");
      }
    },
    fullScreen() {
      if (this.FullScreenBool) {
        var el = document.documentElement
        var rfs =
          el.requestFullScreen ||
          el.webkitRequestFullScreen ||
          el.mozRequestFullScreen ||
          el.msRequestFullScreen
        if (rfs) {
          rfs.call(el)
        } else if (typeof window.ActiveXObject !== 'undefined') {
          var wscript = new ActiveXObject('WScript.Shell')
          if (wscript != null) {
            wscript.SendKeys('{F11}')
          }
        }
      } else {
        var el = document
        var cfs =
          el.cancelFullScreen ||
          el.webkitCancelFullScreen ||
          el.mozCancelFullScreen ||
          el.exitFullScreen
        if (cfs) {
          cfs.call(el)
        } else if (typeof window.ActiveXObject !== 'undefined') {
          var wscript = new ActiveXObject('WScript.Shell')
          if (wscript != null) {
            wscript.SendKeys('{F11}')
          }
        }
      }
      this.FullScreenBool = !this.FullScreenBool
    }
  }
}
</script>

<style lang="scss" scoped>
.rightTool {
  position: absolute;
  bottom: 0;
  height: calc(100vh - 80px);
  //width: 100%;
  transition: right 0.5s;
  z-index: 2;
}
.reveal-box {
  position: absolute;
  bottom: 0.3rem;
  width: 100%;
  text-align: center;
  .reveal {
    background: var(--background-color-skin);
    padding: 0.1rem;
    font-size: 0.14rem;
    display: inline-block;

    // width: 700px;
    .reveal-item {
      margin-right: 0.11rem;
      display: inline-block;
      align-items: center;
      .reveal-ions {
        display: inline-block;
        width: 10px;
        height: 10px;
        background: red;
        margin-right: 0.05rem;
      }
    }
  }
}

.legend {
  min-width: 110px;
  background: var(--background-color-skin);
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  position: absolute;
  bottom: 1.28rem;
  right: 20px;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  padding-right: 15px;
  z-index: 7;
  .leName {
    padding-left: 15px;
  }
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.14rem;
    color: var(--font-color-f1);
    margin-top: 0.05rem;
    white-space: nowrap;
    cursor: pointer;
    .icon {
      margin-left: 12px;
      margin-right:8px;
      text-align: center;
      width: 30px;
      height: auto;
      font-size: 16px;
    }

    .text {
      color: var(--font-color-c1);
    }
    .text.active {
      color: var(--font-color-f1);
    }
  }
  .show {
    color: #ffffff;
  }
}
.MapChangeClass {
  width: 0.9rem;
  height: 0.73rem;
  position: absolute;
  bottom: 0.32rem;
  right: 20px;
  background: var(--background-color-skin);
  transition: 1s width;
  overflow: hidden;
  .MapItem {
    width: 0.82rem;
    height: 0.65rem;
    float: right;
    margin-top: 0.04rem;
    color: white;
    margin-right: 0.04rem;
    cursor: pointer;
    background-size: 100% 100%;
  }
  .MapItem:hover {
    box-shadow: 0px 0px 10px #888888;
  }
}
.MapChangeClass:hover {
  width: 3.5rem;
}
.tool {
  position: absolute;
  top: 0.74rem;
  right: 0.11rem;
  font-size: 12px;
  background: #1e303d;
  border: 1px solid rgba(1, 188, 255, 0.75);
  opacity: 0.98;
  .boxShadow {
    height: 1px;
    margin: 0 5px;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.14);
  }
  li {
    width: 0.77rem;
    height: 0.67rem;
    cursor: pointer;
    overflow: hidden;
    img {
      width: 0.31rem;
      height: auto;
      display: block;
      margin: 0 auto;
      margin-top: 0.08rem;
      margin-bottom: 0.02rem;
    }
    span {
      width: 100%;
      display: block;
      line-height: 1;
      text-align: center;
      color: var(--title-text-color);
    }
  }
  li:hover {
    background: #2a82b0;
  }
  li.active {
    background: #2a82b0;
  }
}
.mapTool {
  background: #0f1d29;
  margin: 2px;
  span {
    display: block;
    padding: 2px 10px;
    text-align: center;
    cursor: pointer;
    img {
      width: 30px;
      height: 30px;
      margin: 0 auto;
    }
  }
  span:hover {
    background: RGBA(24, 54, 81, 0.5);
  }
  span.active {
    background: RGBA(24, 54, 81, 1);
  }
}
</style>
